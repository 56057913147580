$(() => {
  const paymentTypeSelector = 'input[name="request_refund_form[payment_type]"][type=radio]';
  const rentRefundSelector = `${paymentTypeSelector}[value="rent_refund"]`;
  const otherRefundSelector = `${paymentTypeSelector}[value="other"]`;
  const organisationSelector = 'input[name="request_refund_form[organisation]"][type=radio]';
  const organisationReadOnlySelector = 'input[name="request_refund_form[organisation_read_only]"][type=radio]';
  const changeLabel = (title) => { $('.request_refund_form_reference label').contents().last()[0].textContent = title; };
  const shouldHideButton = (items, numberOfRefunds) => items >= numberOfRefunds;
  const emptyFormOrganisations = ['MFL', 'MLL'];

  const hasEmptyFormOrganisations = () => {
    const orgs = document.querySelectorAll(organisationSelector);
    return Array.from(orgs).some((org) => emptyFormOrganisations.includes(org.value));
  };

  const displayEmptyFormAccountInformation = (shouldDisplay) => {
    document.querySelector(rentRefundSelector).disabled = shouldDisplay;
    document.querySelector(otherRefundSelector).checked = shouldDisplay;
    if (shouldDisplay) {
      document.querySelector(otherRefundSelector).dispatchEvent(new Event('change', {}));
    }
  };

  const selectCompany = () => {
    const accountInformation = $('.account-information').data('accounting-info');
    const selectedOrganisation = $(`${organisationSelector}:checked`).val() || $(`${organisationReadOnlySelector}:checked`).val();
    if (!selectedOrganisation) return;

    if (hasEmptyFormOrganisations()) {
      if (emptyFormOrganisations.includes(selectedOrganisation)) {
        displayEmptyFormAccountInformation(true);
      } else {
        displayEmptyFormAccountInformation(false);
      }
    }

    const company = accountInformation[selectedOrganisation][0];
    $('#request_refund_form_company').val(company).prop('readonly', true);
  };

  const selectDepartment = () => {
    const selectedOrganisation = $(`${organisationSelector}:checked`).val() || $(`${organisationReadOnlySelector}:checked`).val();
    if (!selectedOrganisation) return;

    if (selectedOrganisation === 'NG/CANL') {
      $('#request_refund_form_department').val('YYY').prop('readonly', true);
    } else {
      $('#request_refund_form_department').val('').prop('readonly', false);
    }
  };

  $(paymentTypeSelector).on('change', (e) => {
    if (e.currentTarget.value === 'other') {
      $('.account-information').attr('hidden', false);
      changeLabel(' Case reference or description');
      $('#request_refund_form_reference').val('');
      $('#request_refund_form_reference').attr('readonly', false);
    } else {
      $('.account-information').attr('hidden', true);
      changeLabel(' Customer payment reference');
    }
  });

  selectCompany();
  $(organisationSelector).on('change', selectCompany);

  selectDepartment();
  $(organisationSelector).on('change', selectDepartment);

  $('#new_request_refund_form').on('submit', () => {
    const paymentTypeSelected = $(`${paymentTypeSelector}:checked`).val();

    if (paymentTypeSelected !== 'other') {
      $('.acc-info-fields input').val('');
    }
  });

  $('a.view-more-refunds').on('click', function showMoreRefunds() {
    const range = 5;
    const numberOfRefunds = $('.request-refund-list').data('refunds');
    const items = $('.request-refund-list').data('items') + range;

    if (shouldHideButton(items, numberOfRefunds)) {
      $(this).addClass('d-none');
    }

    $('dl.request-refund-info.d-none')
      .slice(0, 5)
      .removeClass('d-none');

    $('.request-refund-list').data('items', items);

    return false;
  });

  $('button.copy-link').on('click', function copy() {
    const copyButton = $(this);
    const target = copyButton.data('target');
    const targetDOM = $(target);
    const ptxUrl = targetDOM.val();

    targetDOM.select();
    navigator.clipboard.writeText(ptxUrl);

    copyButton.removeClass('btn-secondary').addClass('btn-secondary-success');
    copyButton.text('Copied ✅');

    setTimeout(() => {
      copyButton.removeClass('btn-secondary-success').addClass('btn-secondary');
      copyButton.text('Copy');
    }, 5000);
  });

  $('button.check-status').on('click', async function checkStatus() {
    const statusButton = $(this);
    const paymentReference = statusButton.data('reference');

    statusButton.removeClass('btn-secondary').addClass('btn-secondary-success');
    statusButton.text('Checking status ...');

    const response = await fetch(`/agent/request_refund/status?payment_reference=${paymentReference}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const json = await response.json();
    statusButton.parent().text(json.status);
  });
});
