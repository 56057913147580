document.addEventListener('DOMContentLoaded', () => {
  const printButton = document.querySelector('.print-button');

  if (printButton) {
    printButton.addEventListener('click', () => {
      window.print();
      return false;
    });
  }
});
